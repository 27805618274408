.mobileItems{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 50%;

}

@media only screen and (max-width: 1200px) {
    .mobileItems {
      display: none;
    }
  }