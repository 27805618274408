/*-----------------------------------------------------------

  Template Name: Droow React
  Template URI: http://theme.dsngrid.com/droow
  Author: design_grid
  Author URI: https://themeforest.net/user/design_grid
  Version: 1.0.0

-------------------------------------------------------------*/

@import "helper";
@import "global";
@import "pagination/pagination";
@import "forms/forms";
@import "blocks/scrollbar";

:root,
:root .v-light,
:root .v-light-head {
  --bg-color: #f9f9f9;
  --assistant-color: #e6e6e6;
  --font-color: #0009;
  --heading-color: #000;
  --border-color: #f9f9f9;
  --secondary-color: #dda74f;
}

:root .v-dark,
:root .v-dark-head {
  --bg-color: #000;
  --assistant-color: #0c0c0c;
  --font-color: rgba(255, 255, 255, 0.68);
  --heading-color: #fff;
  --border-color: #cacaca;
  --secondary-color: #dda74f;

  color: var(--font-color);
}

:root {
  --body-font: "Montserrat", sans-serif;
  --heading-font: "Montserrat", sans-serif;
  --font-code: Menlo, monaco, Consolas, Lucida Console, monospace;
  --font-pre: "Courier 10 Pitch", Courier, monospace;

  --font-size-base: 16px;
  --font-size-h1: 40px;
  --font-size-h2: 35px;
  --font-size-h3: 30px;
  --font-size-h4: 25px;
  --font-size-h5: 22px;
  --font-size-h6: 16px;
  --menu-size: 12px;

  --line-height-base: 1.4;
  --line-height-content: 1.6;
  --font-weight-heading: 700;
  --font-weight-body: 500;
  --title-heading: 65px;
  --title-section: 42px;

  //--> structure

  @include media_991(min) {
    --margin-padding: 120px;
    --box-padding: 80px;
    --title-block: 22px;

    .container-fluid {
      --bs-gutter-x: 50px;
    }

    .container {
      --bs-gutter-x: 15px;
    }
  }

  @include media_991() {
    --margin-padding: 80px;
    --box-padding: 50px;
    --title-block: 18px;
  }

  @include media_768X991() {
    --bs-gutter-x: 35px;
  }

  @include media_575X768() {
    --bs-gutter-x: 25px;
    --title-heading: 45px;
    --title-section: 32px;
  }

  @include media_575() {
    --bs-gutter-x: 15px;
    --title-heading: 32px;
    --title-section: 28px;
    --box-padding: 40px 30px;
  }
}

@import "typography/typography";
@import "normalize";

html {
  font-size: $font-size-base;
}

body {
  background-color: $bg-color;
  color: $body-color;
  line-height: $line-height-base;
  font-family: $body-font;
  font-weight: $font-weight-body;
  margin: 0;
  text-rendering: optimizeLegibility;
  transition: 100ms;
  transition-property: background-color, color;
}

#main_layout {
  min-height: 100vh;
}

.cover-bg-img,
.cover-bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

.filter {
  filter: url(#filter-radius);
}

.project-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  // margin: 20px;
  height: 100%;
  gap: 40px;
  // margin-top: 60px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: auto 0;
  }

  .project-heading {
    font-size: 36px;
    font-weight: 800;
    color: #fff;
  }

  .project-img {
    width: 100%;
    height: 550px;
    object-fit: contain;
    pointer-events: none;
    border-radius: 10px;
    margin-top: 100px;
    margin-right: 20px;

    @media screen and (max-width: 768px) {
      margin: auto 0;
      width: 90%;
      height: 400px;
    }
  }

  .project-description {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 20px;
  }
}

.tags {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  gap: 4px;
  margin-bottom: 10px;
}

.tag {
  display: inline-block;
  background-color: $secondary-color;
  // border: 1px solid #ccc;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 12px;
  color: #333;
  text-align: center;
  user-select: none;
  margin: 2px;

  &:hover {
    background-color: #000;
    color: white;
  }
}

.half-content {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .hero-img {
    position: absolute;
    top: 0;
    width: 100%;

    &:not(.full-image):not(.padding-image) {
      @include media_768(min) {
        width: 80%;
        left: 20%;
      }
    }
  }

  .post-cat {
    position: relative;
    padding-left: 60px;
    margin-bottom: 20px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -5px;
      width: 40px;
      height: 2px;
      background: #fff;
    }
  }

  .hero-content {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    padding-top: 10%;

    @include media_768(min) {
      padding-left: 7vw;
      padding-top: 10%;
    }
  }
}

.cap {
  position: absolute;
  bottom: 30px;
  left: 0;
  background-image: linear-gradient(to right, #0e0e0e5c 0%, #1b1515 100%);
  padding: 4px 15px;
  color: #fff;
  z-index: 10;

  span {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
}

.fancybox__container {
  --fancybox-thumbs-border-radius: 50%;
  --fancybox-thumbs-ratio: 1;

  --fancybox-thumbs-width: 70px;
  --fancybox-thumbs-height: 70px;

  --fancybox-accent-color: $border-color;
}

.fancybox__thumbs .carousel__slide:not(:last-child) {
  margin-right: 10px;
}

[data-fancybox] {
  cursor: pointer;
}

.dsn-swiper-parallax-transform {
  transition-property: opacity, transform, visibility;
}

.under-header {
  @include media_991(min) {
    position: relative;
    overflow: hidden;
    margin-top: -20vh;

    &.container {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;

      .portfolio-item .inner-img {
        height: 50vh;
      }
    }
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;

  .preloader-before,
  .preloader-after {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    background: $bg-color;
  }

  .preloader-before {
    top: 0;
  }

  .preloader-after {
    bottom: 0;
  }

  .preloader-block {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;

    .title {
      position: absolute;
      left: 0;
      bottom: calc(50% + 16px);
      width: 100%;
      text-align: center;
      z-index: 2;
      font-size: 25px;
      text-transform: uppercase;
      letter-spacing: 8px;
      color: $heading-color;
    }

    .percent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-weight: 700;
      letter-spacing: 8px;
      font-size: 24vw;
      color: rgba(54, 54, 53, 0.1);
      z-index: 1;
    }

    .loading {
      position: absolute;
      top: calc(50% + 16px);
      left: 0;
      width: 100%;
      text-align: center;
      z-index: 2;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 8px;
      color: $heading-color;
      -webkit-animation: blink-animation 2.2s linear infinite;
    }
  }

  .preloader-bar {
    position: absolute;
    z-index: 2;
    top: calc(50% - 1px);
    right: 0;
    width: 100%;
    height: 2px;
    background: $assistant-color;

    .preloader-progress {
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
      height: 100%;
      width: 0;
      background: $heading-color;
    }
  }
}

#root > .dsn-scrollbar[data-scrollbar] {
  height: 100vh;
}

.carousel-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

// Custom black scrollbar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  // border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 5px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ::-ms-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-ms-scrollbar-thumb {
    background-color: #090909;
    // border-radius: 5px;
  }

  ::-ms-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}

.services-link-list {
  display: flex;
  align-items: center;
  padding: 5px;
  color: #dda74f;
  cursor: pointer;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.service-hero-div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 0 40px;
  height: 50dvh;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    padding: 0 40px;
    height: 55dvh;
  }
}

.service-hero-img {
  width: 50%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    width: 100%;
    // height: 50%;
    margin-top: 120px;
    margin-bottom: 20px;
    display: none;
  }
}
